export const backgroundMusicData = [
  {
    name: "Kisah Kasih Di Sekolah - Chrisye",
    path: "assets/audio/Kisah Kasih Di Sekolah - Chrisye.mp3",
  },
  {
    name: "Aku Cinta Dia - Chrisye",
    path: "assets/audio/Aku Cinta Dia - Chrisye.mp3",
  },
  {
    name: "Cantik - Kahitna",
    path: "assets/audio/Cantik - Kahitna.mp3",
  },
];
