import React from "react";
import GenerateTemplate from "../generate-template/GenerateTemplate";
import { useSelector } from "react-redux";
import { RootState } from "../../services/redux/Store";
import { TemplateMapper } from "../../libs/Template";

const MobileView = () => {
  const templatePersonalized = useSelector(
    (state: RootState) => state.template
  );
  const template = TemplateMapper(templatePersonalized);
  // console.log("parent", templatePersonalized);

  return (
    <div className="relative mt-16 mx-auto border-gray-800 bg-gray-800 border-[14px] max-2xl:border-[8px] max-xl:border-[6px] rounded-[2.5rem] h-[669px] w-[328px] shadow-xl max-2xl:h-[560px] max-2xl:w-[270px] max-xl:h-[475px] max-xl:w-[230px] max-lg:h-[405px] max-lg:w-[197px]">
      <div className="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute max-2xl:w-[120px] max-2xl:h-[10px] max-xl:w-[107px] max-xl:h-[8px] max-lg:w-[91px] max-lg:h-[5px]"></div>
      <div className="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] max-2xl:-start-[10px] max-xl:-start-[8px] top-[124px] rounded-s-lg max-2xl:h-[39px] max-2xl:w-[2.55px] max-xl:h-[33px] max-xl:w-[2.17px] max-lg:h-[28px] max-lg:w-[1.84px]"></div>
      <div className="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] max-2xl:-start-[10px] max-xl:-start-[8px] top-[178px] rounded-s-lg max-2xl:h-[39px] max-2xl:w-[2.55px] max-xl:h-[33px] max-xl:w-[2.17px] max-lg:h-[28px] max-lg:w-[1.84px]"></div>
      <div className="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] max-2xl:-end-[10px] max-xl:-end-[8px] top-[142px] rounded-e-lg max-2xl:h-[54px] max-2xl:w-[2.55px] max-xl:h-[46px] max-xl:w-[2.17px] max-lg:h-[39px] max-lg:w-[1.84px]"></div>
      <div
        className={`rounded-[2rem] ${template.templateId === "" ? "bg-white" : templatePersonalized.templateColor} scrollbar-hidden overflow-y-auto w-[300px] h-[641px] max-2xl:w-[255px] max-2xl:h-[544px] max-xl:w-[217px] max-xl:h-[462px] max-lg:w-[185px] max-lg:h-[392px]`}
      >
        {template.templateId === "" ? (
          <div className="flex justify-center items-center h-full">
            <img className="w-4/6" src="/assets/images/logo.png" alt="" />
          </div>
        ) : (
          <GenerateTemplate template={template} view="mobile" />
        )}
      </div>
    </div>
  );
};

export default MobileView;
