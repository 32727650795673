import React from 'react'

const RegisterPageRight = () => {
  return (
    <div className='bg-custom-pink h-full flex items-center justify-center'>
        <img src="assets/images/register.png" alt="register" />
    </div>
  )
}

export default RegisterPageRight