export const templatesData = [
    {
        id: "282a56a0-516d-48ab-ac6c-679e5417e034",
        title: "Black Pink Template",
        image: "assets/images/black-pink-showcase.png",
    },
    {
        id: "efde7119-e53d-4733-9cc7-38cd2300d205",
        title: "White Blossom",
        image: "assets/images/white-blossom-showcase.png",
    }
]