export const AccountBankData = [
  {
    name: "BCA",
    image: "/assets/images/bca.png"
  },
  {
    name: "CeritaBank",
    image: "/assets/images/logo.png",
  },
  {
    name: "Mandiri",
    image: "/assets/images/mandiri.png",
  },
];
